<template>
  <el-row class="error_page">
    <el-col :span="12" class="row_right"
      ><img :src="src" :alt="code" class="img"
    /></el-col>
    <el-col :span="12" class="row_left">
      <div>
        <h1 class="num">{{ code }}</h1>
        <div class="desc">{{ desc }}</div>
        <el-button type="primary" size="large" @click="backHome"
          >返回首页</el-button
        >
      </div>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: "error_page",
  data() {
    return {};
  },
  props: {
    code: String,
    desc: String,
    src: String,
  },
  methods: {
    backHome() {
      this.$router.push({
        path: "/",
      });
    },
  },
};
</script>

<style>
</style>
