<template>
  <error-page code="404" desc="抱歉，你访问的页面不存在" :src="src" />
</template>

<script>
import error404 from "@/assets/images/error/error-404.svg";
import errorPage from "./module/error-page";
export default {
  components: {
    errorPage,
  },
  data() {
    return {
      src: error404,
    };
  },
};
</script>
<style>
</style>
